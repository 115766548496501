import { combineReducers } from "redux";
import userReducer from "./userReducer";
import productReducer from "./productReducer";
import orderReducer from "./orderReducer";
import streamReducer from "./streamReducer";
import locationReducer from "./regionReducer";
import categoryReducer from "./categoryReducer";
import transactionReducer from "./transactionReducer";
import packageReducer from "./packageReducer";

const reducer = combineReducers({
  user: userReducer,
  products: productReducer,
  orders: orderReducer,
  stream: streamReducer,
  locations: locationReducer,
  categorys: categoryReducer,
  transaction: transactionReducer,
  package: packageReducer,
});

export default reducer;
