const INITIAL_STATE = {
  list: [],
  loginStep: 0,
  loading: false,
  message: null,
  sidebarShow: "responsive",
  transaction_total: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, { type, payload, ...rest }) => {
  switch (type) {
    // all products
    case "fetch_transaction_start":
      return { ...state, loading: true, message: "" };
    case "fetch_transaction_error":
      return { ...state, message: payload, loading: false };
    case "fetch_transaction_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        list: payload.data,
        transaction_total: payload.meta.total,
      };

    // all products
    case "fetch_payment_requests_start":
      return { ...state, loading: true, message: "" };
    case "fetch_payment_requests_error":
      return { ...state, message: payload, loading: false };
    case "fetch_payment_requests_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        payment_list: payload.data,
      };

    // user update
    case "update_transaction_start":
      return { ...state, loading: true, message: "" };
    case "update_transaction_error":
      return { ...state, message: payload, loading: false };
    case "update_transaction_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
      };

    // user update
    case "update_success_payment_start":
      return { ...state, loading: true, message: "" };
    case "update_success_payment_error":
      return { ...state, message: payload, loading: false };
    case "update_success_payment_success":
      let payment_success;
      state.list.forEach((item, index) => {
        if (item.id === payload.data.id) {
          payment_success = index;
        }
      });
      state.list[payment_success] = payload.data;
      return {
        ...state,
        loading: false,
        list: [...state.list],
      };

    // user update
    case "update_success_transaction_start":
      return { ...state, loading: true, message: "" };
    case "update_success_transaction_error":
      return { ...state, message: payload, loading: false };
    case "update_success_transaction_success":
      let transaction_success;
      state.list.forEach((item, index) => {
        if (item.id === payload.data.id) {
          transaction_success = index;
        }
      });
      state.list[transaction_success] = payload.data;
      return {
        ...state,
        loading: false,
        list: [...state.list],
      };

    // user update
    case "update_user_balance_start":
      return { ...state, loading: true, message: "" };
    case "update_user_balance_error":
      return { ...state, message: payload, loading: false };
    case "update_user_balance_success":
      let updateBalace;
      state.list.forEach((item, index) => {
        if (item.id === payload.data.id) {
          updateBalace = index;
        }
      });
      state.list[updateBalace] = payload.data;
      return {
        ...state,
        loading: false,
        list: [...state.list],
      };

    // user update
    case "update_reject_payment_start":
      return { ...state, loading: true, message: "" };
    case "update_reject_payment_error":
      return { ...state, message: payload, loading: false };
    case "update_reject_payment_success":
      let payment_reject;
      state.list.forEach((item, index) => {
        if (item.id === payload.data.id) {
          payment_reject = index;
        }
      });
      state.list[payment_reject] = payload.data;
      return {
        ...state,
        loading: false,
        list: [...state.list],
      };

    /* other */
    default:
      return state;
  }
};
