const INITIAL_STATE = {
  list: [],
  loginStep: 0,
  loading: false,
  message: null,
  sidebarShow: "responsive",
  data: {},
  page: 1,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, { type, payload, ...rest }) => {
  switch (type) {
    // all products
    case "fetch_products_start":
      return { ...state, loading: true, message: "" };
    case "fetch_products_error":
      return { ...state, message: payload, loading: false };
    case "fetch_products_success":
      // agar pustoy bolsa
      if (!payload.data.length || !payload.meta) {
        return {
          ...state,
          loading: false,
          list: [],
          total_orders: payload.meta.total,
        };
      }

      if (payload.meta.current_page > 1) {
        return {
          ...state,
          loading: false,
          list: [...state.list, ...payload.data],
          hasMore: payload.meta.current_page < payload.meta.last_page,
          total_orders: payload.meta.total,
        };
      }

      return {
        ...state,
        loading: false,
        list: payload.data,
        hasMore: payload.meta.current_page < payload.meta.last_page,
        total_orders: payload.meta.total,
      };

    // one product
    case "fetch_one_product_start":
      return { ...state, loading: true, message: "" };
    case "fetch_one_product_error":
      return { ...state, message: payload, loading: false };
    case "fetch_one_product_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        data: payload.data,
      };
    case "clear_product_success":
      return {
        ...state,
        data: {},
      };

    // update product
    case "update_product_start":
      return { ...state, loading: true, message: "" };
    case "update_product_error":
      return { ...state, message: payload, loading: false };
    case "update_product_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        data: payload.data,
      };

    // create product
    case "create_product_start":
      return { ...state, loading: true, message: "" };
    case "create_product_error":
      return { ...state, message: payload, loading: false };
    case "create_product_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        data: payload,
      };

    // delete product
    case "delete_product_start":
      return { ...state, loading: true, message: "" };
    case "delete_product_error":
      return { ...state, message: payload, loading: false };
    case "delete_product_success":
      let newList = state.list.filter((item) => item._id !== payload.id);
      return {
        ...state,
        message: payload.message,
        loading: false,
        list: newList,
      };
    /* other */
    default:
      return state;
  }
};
