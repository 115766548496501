const INITIAL_STATE = {
  list: [],
  loginStep: 0,
  loading: false,
  message: null,
  sidebarShow: "responsive",
  success_data: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, { type, payload, ...rest }) => {
  switch (type) {
    // all products
    case "fetch_regions_start":
      return { ...state, loading: true, message: "" };
    case "fetch_regions_error":
      return { ...state, message: payload, loading: false };
    case "fetch_regions_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        list: payload.data,
      };

    // all products
    case "fetch_location_one_start":
      return { ...state, loading: true, message: "" };
    case "fetch_location_one_error":
      return { ...state, message: payload, loading: false };
    case "fetch_location_one_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        data: payload.data,
      };

    // create region
    case "create_region_start":
      return { ...state, loading: true, message: "" };
    case "create_region_error":
      return { ...state, message: payload, loading: false };
    case "create_region_success":
      const newList = [...state.list, payload.data && payload.data];
      return {
        ...state,
        message: payload.message,
        loading: false,
        list: newList,
      };

    // create region
    case "create_district_start":
      return { ...state, loading: true, message: "", success_data: false };
    case "create_district_error":
      return { ...state, message: payload, loading: false };
    case "create_district_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        success_data: true,
      };

    // create region
    case "delete_location_start":
      return { ...state, loading: true, message: "" };
    case "delete_location_error":
      return { ...state, message: payload, loading: false };
    case "delete_location_success":
      let newList2 = state.list.filter((item) => item.id !== payload);
      return {
        ...state,
        message: payload.message,
        loading: false,
        list: newList2,
      };

    // create region
    case "delete_district_start":
      return { ...state, loading: true, message: "", success_data: false };
    case "delete_district_error":
      return { ...state, message: payload, loading: false };
    case "delete_district_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        success_data: true,
      };

    /* other */
    default:
      return state;
  }
};
