const INITIAL_STATE = {
  list: [],
  loginStep: 0,
  loading: false,
  message: null,
  sidebarShow: "responsive",
  operator: {},
  hasMore: true,
  total_orders: 0,
  data: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, { type, payload, ...rest }) => {
  switch (type) {
    // all products
    case "fetch_orders_start":
      return { ...state, loading: true, message: "", hasMore: true };
    case "fetch_orders_error":
      return { ...state, message: payload, loading: false };
    case "fetch_orders_success":
      // agar pustoy bolsa
      if (!payload.data.length || !payload.meta) {
        return {
          ...state,
          loading: false,
          list: [],
          total_orders: payload.meta.total,
        };
      }

      if (payload.meta.current_page > 1) {
        return {
          ...state,
          loading: false,
          list: [...state.list, ...payload.data],
          hasMore: payload.meta.current_page < payload.meta.last_page,
          total_orders: payload.meta.total,
        };
      }

      return {
        ...state,
        loading: false,
        list: payload.data,
        hasMore: payload.meta.current_page < payload.meta.last_page,
        total_orders: payload.meta.total,
      };

    case "fetch_orders_status_start":
      return { ...state, loading: true, message: "", hasMore: true };
    case "fetch_orders_status_error":
      return { ...state, message: payload, loading: false };
    case "fetch_orders_status_success":
      return {
        ...state,
        loading: false,
        list: payload.data,
      };

    // all products
    case "fetch_customer_orders_start":
      return { ...state, loading: true, message: "" };
    case "fetch_customer_orders_error":
      return { ...state, message: payload, loading: false };
    case "fetch_customer_orders_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        list: payload.orders,
      };

    // all products
    case "fetch_order_one_start":
      return { ...state, loading: true, message: "" };
    case "fetch_order_one_error":
      return { ...state, message: payload, loading: false };
    case "fetch_order_one_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        data: payload.data,
      };

    // Get Operator Order
    case "get_operator_order_start":
      return { ...state, loading: true, message: "" };
    case "get_operator_order_error":
      return { ...state, message: payload, loading: false };
    case "get_operator_order_success":
      let operatorItem;
      state.list.forEach((item, index) => {
        if (item.id === payload.id) {
          operatorItem = index;
        }
      });
      state.list[operatorItem] = payload.data.data;
      return {
        ...state,
        loading: false,
        list: [...state.list],
      };

    // Update Order
    case "update_order_start":
      return { ...state, loading: true, message: "" };
    case "update_order_error":
      return { ...state, message: payload, loading: false };
    case "update_order_success":
      let orderItem;
      state.list.forEach((item, index) => {
        if (item.id === payload.id) {
          orderItem = index;
        }
      });
      state.list[orderItem] = payload.data.data;
      return {
        ...state,
        loading: false,
        list: [...state.list],
      };

    // Update Order
    case "order_operator_clear_start":
      return { ...state, loading: true, message: "" };
    case "order_operator_clear_error":
      return { ...state, message: payload, loading: false };
    case "order_operator_clear_success":
      let clear_operator;
      state.list.forEach((item, index) => {
        if (item.id === payload.id) {
          clear_operator = index;
        }
      });
      state.list[clear_operator] = payload.data.data;
      return {
        ...state,
        loading: false,
        list: [...state.list],
      };

    // Update Order
    case "update_order_money_start":
      return { ...state, loading: true, message: "" };
    case "update_order_money_error":
      return { ...state, message: payload, loading: false };
    case "update_order_money_success":
      let orderUpdateMoneyOrder;
      state.list.forEach((item, index) => {
        if (item._id === payload.id) {
          orderUpdateMoneyOrder = index;
        }
      });
      state.list[orderUpdateMoneyOrder] = payload.data.data;
      return {
        ...state,
        loading: false,
        list: [...state.list],
      };

    // Update Order
    case "order_increment_product_start":
      return { ...state, loading: true, message: "" };
    case "order_increment_product_error":
      return { ...state, message: payload, loading: false };
    case "order_increment_product_success":
      return {
        ...state,
        loading: false,
        data: payload.data,
      };

    // Update Order
    case "order_increment_faster_product_start":
      return { ...state, loading: true, message: "" };
    case "order_increment_faster_product_error":
      return { ...state, message: payload, loading: false };
    case "order_increment_faster_product_success":
      let orderUpdateFaster;
      state.list.forEach((item, index) => {
        if (item.id === payload.data.id) {
          orderUpdateFaster = index;
        }
      });
      state.list[orderUpdateFaster] = payload.data;
      return {
        ...state,
        loading: false,
        list: [...state.list],
      };

    // Update Order
    case "order_delete_product_start":
      return { ...state, loading: true, message: "" };
    case "order_delete_product_error":
      return { ...state, message: payload, loading: false };
    case "order_delete_product_success":
      return {
        ...state,
        loading: false,
        data: payload.data,
      };

    // Update Order
    case "order_add_product_start":
      return { ...state, loading: true, message: "" };
    case "order_add_product_error":
      return { ...state, message: payload, loading: false };
    case "order_add_product_success":
      return {
        ...state,
        loading: false,
        data: payload.data,
      };

    /* other */
    default:
      return state;
  }
};
