const INITIAL_STATE = {
  list: [],
  loginStep: 0,
  loading: false,
  message: null,
  sidebarShow: "responsive",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, { type, payload, ...rest }) => {
  switch (type) {
    // all products
    case "fetch_categorys_start":
      return { ...state, loading: true, message: "" };
    case "fetch_categorys_error":
      return { ...state, message: payload, loading: false };
    case "fetch_categorys_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        list: payload.data,
      };

    // all products
    case "create_category_start":
      return { ...state, loading: true, message: "" };
    case "create_category_error":
      return { ...state, message: payload, loading: false };
    case "create_category_success":
      let newList = [...state.list, payload.data && payload.data];
      return {
        ...state,
        message: payload.message,
        loading: false,
        list: newList,
      };

    // all products
    case "update_category_start":
      return { ...state, loading: true, message: "" };
    case "update_category_error":
      return { ...state, message: payload, loading: false };
    case "update_category_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        list: [...state.list],
      };

    // delete category
    case "delete_category_start":
      return { ...state, loading: true, message: "" };
    case "delete_category_error":
      return { ...state, message: payload, loading: false };
    case "delete_category_success":
      let newList2 = state.list.filter((item) => item.id !== payload);
      return {
        ...state,
        message: payload.message,
        loading: false,
        list: newList2,
      };

    /* other */
    default:
      return state;
  }
};
