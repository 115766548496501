import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./assets/scss/style.scss";
import { ToastContainer } from "react-toastify";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
// Pages
const Login = React.lazy(() => import("./views/auth/login/Login"));
const Register = React.lazy(() => import("./views/auth/register/Register"));
// token
const token = window.localStorage.getItem("@jsonWebTokenManager");

class App extends Component {
  render() {
    return (
      <>
        <ToastContainer />
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                path="/"
                name="Home"
                render={(props) =>
                  token ? <TheLayout {...props} /> : <Login {...props} />
                }
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </>
    );
  }
}

export default App;
