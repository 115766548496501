const INITIAL_STATE = {
  list: [],
  loginStep: 0,
  loading: false,
  message: null,
  sidebarShow: "responsive",
  data: {},
  page: 1,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, { type, payload, ...rest }) => {
  switch (type) {
    // create package
    case "create_package_start":
      return { ...state, loading: true, message: "" };
    case "create_package_error":
      return { ...state, message: payload, loading: false };
    case "create_package_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
      };

    // one package
    case "fetch_package_all_start":
      return { ...state, loading: true, message: "" };
    case "fetch_package_all_error":
      return { ...state, message: payload, loading: false };
    case "fetch_package_all_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        list: payload.data,
      };

    // one package
    case "delivery_start_calculator_start":
      return { ...state, loading: true, message: "" };
    case "delivery_start_calculator_error":
      return { ...state, message: payload, loading: false };
    case "delivery_start_calculator_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        // data: payload.data,
      };

    // one package
    case "fetch_one_package_start":
      return { ...state, loading: true, message: "" };
    case "fetch_one_package_error":
      return { ...state, message: payload, loading: false };
    case "fetch_one_package_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        list: payload.data,
      };

    // one package
    case "fetch_packages_start":
      return { ...state, loading: true, message: "" };
    case "fetch_packages_error":
      return { ...state, message: payload, loading: false };
    case "fetch_packages_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        list: payload.data,
        total_number: payload.meta.total,
      };

    // update package
    case "update_package_start":
      return { ...state, loading: true, message: "" };
    case "update_package_error":
      return { ...state, message: payload, loading: false };
    case "update_package_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        data: payload.data,
      };

    // update package
    case "returned_package_start":
      return { ...state, loading: true, message: "" };
    case "returned_package_error":
      return { ...state, message: payload, loading: false };
    case "returned_package_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        data: payload.data,
      };

    // update package
    case "to_pay_order_start":
      return { ...state, loading: true, message: "" };
    case "to_pay_order_error":
      return { ...state, message: payload, loading: false };
    case "to_pay_order_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        data: payload.data,
      };

    // update package
    case "cancelled_package_start":
      return { ...state, loading: true, message: "" };
    case "cancelled_package_error":
      return { ...state, message: payload, loading: false };
    case "cancelled_package_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        data: payload.data,
      };

    // update package
    case "panding_package_start":
      return { ...state, loading: true, message: "" };
    case "panding_package_error":
      return { ...state, message: payload, loading: false };
    case "panding_package_success":
      return {
        ...state,
        message: payload.message,
        loading: false,
        data: payload.data,
      };

    // Update Order
    case "delivery_attach_courier_start":
      return { ...state, loading: true, message: "" };
    case "delivery_attach_courier_error":
      return { ...state, message: payload, loading: false };
    case "delivery_attach_courier_success":
      let delivery_attach_courier;
      state.list.forEach((item, index) => {
        if (item.id === payload.data.id) {
          delivery_attach_courier = index;
        }
      });
      state.list[delivery_attach_courier] = payload.data;
      return {
        ...state,
        loading: false,
        list: [...state.list],
      };

    // Update Order
    case "update_delivery_status_start":
      return { ...state, loading: true, message: "" };
    case "update_delivery_status_error":
      return { ...state, message: payload, loading: false };
    case "update_delivery_status_success":
      let delivery_status;
      state.list.forEach((item, index) => {
        if (item.id === payload.data.id) {
          delivery_status = index;
        }
      });
      state.list[delivery_status] = payload.data;
      return {
        ...state,
        loading: false,
        list: [...state.list],
      };

    // Update Order
    case "delete_packages_start":
      return { ...state, loading: true, message: "" };
    case "delete_packages_error":
      return { ...state, message: payload, loading: false };
    case "delete_packages_success":
      // let delete_package;
      // state.list.forEach((item, index) => {
      //   if (item.id === payload.data.id) {
      //     delete_package = index;
      //   }
      // });
      // state.list[delete_package] = payload.data;
      return {
        ...state,
        loading: false,
      };

    /* other */
    default:
      return state;
  }
};
